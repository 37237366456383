'use strict';

import 'regenerator-runtime/runtime.js';

import { Modal, Dropdown, Scrollspy } from 'bootstrap';

import smoothScrolling from './components/smooth-scrolling';
import { watchDetailsNavs } from './components/details-nav';

import '../scss/main.scss';

const loadIcons = () => {
  import('./fontawesome-loader').then(({ fontawesomeLoader }) => {
    fontawesomeLoader()
  });
};

const loadYoutubeModals = () => {
  if (document.querySelector('.youtube-modal')) {
    import('./components/youtube-modal').then(({ YoutubeModal }) => {
      document.querySelectorAll('.youtube-modal').forEach((youtubeModal) => {
        let modal = new YoutubeModal({
          el: youtubeModal.querySelector('.youtube-modal__item'),
          videoId: youtubeModal.querySelector('.youtube-modal__item').dataset.videoid,
        });
        youtubeModal.addEventListener('focusout', () => {
          modal.pauseVideo();
        })
      });
    });
  }
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    loadYoutubeModals();
    loadIcons();
    watchDetailsNavs();
  });
} else {
  loadYoutubeModals();
  loadIcons();
  watchDetailsNavs();
}

window.addEventListener('resize', () => {
  document.querySelectorAll('.layout-faq-page details')
});

window.onload = () => {
  if (document.querySelector('.page-header')) {
    smoothScrolling(document.querySelector('.page-header').offsetHeight);
  } else {
    smoothScrolling();
  }
};