'use strict';

/**
 * Enable Smooth Scrolling
 * Based on the solution by Chris Coyier of CSS-Tricks.com
 * 
 * @param {int} offset
 */
export default ( offset ) => {
    document.querySelectorAll( 'a.smoothscroll[href*="#"]' ).forEach( function ( item, idx ) {
        item.addEventListener( 'click', (e) => {
            e.preventDefault();
            smoothScrollTo( item, offset );
        } );
    } );
}

/**
 * Smooth scrolls from item in page
 * 
 * @param {Object} linkItem 
 * @param {int} offset
 */
const smoothScrollTo = ( linkItem, offset ) => {
    if ( location.pathname.replace( /^\//, '' ) == linkItem.pathname.replace( /^\//, '' ) && location.hostname == linkItem.hostname ) {
        let target = linkItem.hash;
        target = target.length ? target : document.querySelector( '[name=' + linkItem.hash.slice( 1 ) + ']' );
        offset == undefined ? 0 : offset;
        if ( target.length ) {
            window.scroll( {
                behavior: 'smooth',
                left: 0,
                top: document.querySelector( target ).getBoundingClientRect().top  + window.scrollY - offset
            } );
        }
    }
}