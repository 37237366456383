'use strict';

const watchDetailsNavs = () => {
  let details = document.querySelectorAll('details.nav');
  let ro = new ResizeObserver((entries) => {
    for (let entry of entries) {
      const cr = entry.contentRect;
      if (cr.width < parseInt(getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md'))) {
        details.forEach((nav) => {
          nav.removeAttribute('open');
        });
      } else {
        details.forEach((nav) => {
          nav.setAttribute('open', 'open');
        });
      }
    }
  });
  ro.observe(document.querySelector('.page-header'));
};
export { watchDetailsNavs };
